import React from "react";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import LogoImg from "../../assets/logo.jpg"

const styles: { [key: string]: React.CSSProperties } = {
    link: {
        color: "black",
        textDecoration: "none",
    },
};

export const HeaderLogo = () => {
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={5}
            style={{margin: "0 auto", maxWidth: "300px"}}
        >
            <Link
                to={{
                    pathname: "/top",
                }}
                style={styles.link}
            >
                <Grid
                    item
                    style={{
                        marginBottom: "10px",
                        marginTop: "15px"
                    }}
                >
                    <img src={LogoImg} style={{width: '190px'}}/>
                </Grid>
            </Link>
        </Grid>
    );
};
