import React from 'react';
import {Grid} from "@material-ui/core";

export const HowToUse = () =>{
    return (
        <div>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12} style={{marginBottom: "20px"}}>
                    AOKA-Tripods　ARは
                </Grid>
            </Grid>
        </div>
    )
}
