import React from 'react';
import kg254c from '../../assets/kg254c.jpg';
import {Button, Card, CardActions, CardContent, CardMedia, Grid, Link, Typography} from "@material-ui/core";
import {isChrome, isIOS, isSafari} from 'react-device-detect';
import {Helmet} from "react-helmet";


export const Top: React.FC = () => {
    console.log(isIOS)

    return (
        <>

            {
                isIOS && (isChrome || isSafari)  ?

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} style={{marginBottom: "20px"}}>
                        <Typography variant="body1" style={{margin: "20px 15px 30px 15px"}}>
                            各製品の実際の大きさを、ARで確認していただけます。<br/><br/>
                            ARで表示の際実際のサイズとの誤差があります。<br/>
                            あくまで参考としてご覧いただき、ご購入前に必ずスペック表にあるサイズをご確認ください。
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: "20px"}}>
                        <Card style={{maxWidth: "300px"}}>
                            <CardMedia image={kg254c} title="KG254Cトラベラー三脚"
                                       style={{height: '0', paddingTop: '100%'}}/>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    KG254Cトラベラー三脚
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    段数：4
                                    収納高：579mm
                                    全伸長：1624mm
                                    最低高：179mm
                                    耐荷重：16kg
                                    本体重量：1.41kg
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="medium" color="primary" rel="ar"
                                        href='https://storage.googleapis.com/aoka3dtestre/254ched%20v9-published.usdz'>
                                    伸ばした状態
                                </Button>
                                <Button size="medium" color="primary" rel="ar"
                                        href='https://storage.googleapis.com/aoka3dtestre/254cfolded%20v8-published.usdz'>
                                    畳んだ状態
                                </Button>
                                <Button size="medium" color="primary" target="_blank"
                                        href='https://aoka.camera/products/aoka-traveler-kg254c'>
                                    商品詳細
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: "20px"}}>
                        <Button size="medium" color="primary" target="_blank"
                                href='https://www.aoka.camera'>
                            AOKA-Tripodsショップを開く
                        </Button>
                    </Grid>
                </Grid>
                :
                <>
                    <div style={{
                        textAlign: "center",
                        marginTop: "20px"
                    }}>大変申し訳ございませんが、以下をご確認ください。<br/>　<br/>
                        ・SafariもしくはChromeで開いてください。TwitterやLineなどのアプリ内ブラウザでは開けません。<br/>
                        ・iOS13以降が必須です。Android,PCは対応していません<br/>
                    </div>
                    <br/>
                    <div style={{textAlign: "center"}}>
                        <a href="https://www.aoka.camera">
                            AOKA-Tripodsショップを開く
                        </a>
                    </div>
                </>
        }

        </>
    )
}
