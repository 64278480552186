import React from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import "./App.css";
import {HeaderLogo} from "./components/components/HeaderLogo";
import {Top} from "./components/pages/top";
import {HowToUse} from "./components/pages/HowToUse";
import {Helmet} from "react-helmet";


function App() {
    return (
        <>
            <BrowserRouter>

                <HeaderLogo/>

                <Switch>
                    <Route path="/top" exact component={Top}/>
                    <Route component={Top}/>
                    <Route component={HowToUse}/>
                    <Route component={() => <Redirect to="/top"/>}/>
                </Switch>
            </BrowserRouter>
        </>
    );
}


export default App;
